import React, { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { addContactUsService } from "../services/homepageServices";
import { useNavigate } from "react-router-dom";
import ThanksModal from "../Common/ThanksModal/thanksModal";

function ContactUsPage() {
  const navigate = useNavigate();
  const [thanksModal, setThanksModal] = useState(false);

  const [formDataValue, setFormDataValue] = useState({
    name: { value: "", warning: false },
    email: { value: "", warning: false },
    message: { value: "", warning: false },
  });
  console.log(formDataValue, "formDataValue");
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormDataValue((prev) => ({
      ...prev,
      [name]: { value: value?.trimStart(), warning: value === "" },
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = { ...formDataValue };
    data = {
      ...data,
      name: { ...data.name, warning: data.name.value === "" },
      email: { ...data.email },
      message: { ...data.message, warning: data.message.value === "" },
      is_weplaced: false,
    };
    setFormDataValue({ ...data });
    if (!data.name.warning && !data.email.warning && !data.message.warning) {
      const response = await addContactUsService({
        name: data.name.value,
        email: data.email.value,
        message: data.message.value,
        is_weplaced: false,
      });
      if (response.status) {
        setThanksModal(true);
        setFormDataValue({
          name: { value: "", warning: false },
          email: { value: "", warning: false },
          message: { value: "", warning: false },
        });
      }
    }
  };

  return (
    <>
      <div className="col-lg-6">
        <div className="footer-right-portion position-relative">
          <span className="span_line_contact"></span>

          <h4 className="mb-5">Contact Us</h4>
          <form onSubmit={onSubmit}>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicName"
                >
                  <Form.Label
                    style={{ top: "10px", left: "18px", fontWeight: "bold" }}
                  >
                    Name
                  </Form.Label>
                  <Form.Control
                    style={{ height: "80px", paddingTop: "29px" }}
                    type="text"
                    name="name"
                    placeholder="Enter your name here"
                    autoComplete="off"
                    onChange={onChange}
                    value={formDataValue?.name?.value}
                  />
                  {formDataValue?.name?.warning && (
                    <span className="text-danger">Enter the name</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="formBasicEmail"
                  className="mb-3 position-relative"
                >
                  <Form.Label
                    style={{ top: "10px", left: "18px", fontWeight: "bold" }}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    style={{ height: "80px", paddingTop: "29px" }}
                    type="email"
                    name="email"
                    placeholder="Enter your email here"
                    autoComplete="off"
                    onChange={onChange}
                    value={formDataValue?.email?.value}
                  />
                  {formDataValue?.email?.warning && (
                    <span className="text-danger"> Enter your email </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicCompanyName"
                >
                  <Form.Label
                    style={{ top: "10px", left: "17px", fontWeight: "bold" }}
                  >
                    Message
                  </Form.Label>
                  <Form.Control
                    as={"textarea"}
                    rows={4}
                    style={{ paddingTop: "29px" }}
                    type="text"
                    name="message"
                    placeholder="Enter your Message"
                    autoComplete="off"
                    onChange={onChange}
                    value={formDataValue?.message?.value}
                  />
                  {formDataValue?.message?.warning && (
                    <span className="text-danger">Enter the message</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <button
              style={{ width: "350px !important" }}
              className="steper_submit_button download-btn text-light footer-submit"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* ThanksModal component */}
      <ThanksModal
        open={thanksModal}
        setOpen={setThanksModal}
        content="Your message has been sent successfully!"
        continueBtnFun={() => navigate("/")}
      />
    </>
  );
}

export default ContactUsPage;
